import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "Gustaf Elbander", // e.g: 'Name | Developer'
  lang: "", // e.g: en, es, fr, jp
  description: "", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hi, my name is ",
  name: "Gustaf Elbander,",
  subtitle: " and I'm a fullstack developer.",
  cta: "Know more",
};

// ABOUT DATA
export const aboutData = {
  img: "me.jpg",
  paragraphOne:
    "I am an experienced developer that handles the full stack and lately I've been focused on video-streaming, payments, and infrastructure.",
  paragraphTwo:
    " What people usually like is my perspective. I try to see the whole picture when making technical decisions and I also understand the different stakeholder's needs. I'm proficient with frontend technologies but stronger in the backend section.",
  paragraphThree:
    "I'm a fast learner with primary drivers being creative and structured. Calm, social and resilient to stress . I'll be delivering in various environments and setups.",
  resume: "resume.pdf", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: "singalonggray.jpg",
    title: "Sing Along",
    info:
      "Singalong is a singing app made for music-lovers. Currently I'm working with a great team to support the singalong app. Me, as a backend developer, make sure that new features gets implemented while making sure eveything works as expected.",
    info2:
      "[python, javascript, django, REST, hls, vue.js, google-cloud, ffmpeg, terraform... ]",
    url: "https://singalong.me",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "dicopaygray.png",
    title: "Dicopay",
    info:
      "Dicopay have a mobile app that simplifies the process of creating invoices for small/medium-sized businesses. I was there to replace their current payment solution but also maintain their day-to-day business.",
    info2:
      "[python, javascript, django, REST, payments, security, kubernetes, helm... ]",
    url: "https://dicopay.com/",
    repo: "", // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "Want to get in touch?",
  btn: "",
  email: "gustaf@mkbd.se",
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "stack-overflow",
      url: "https://stackoverflow.com/users/671057/gelbander",
    },
    {
      id: nanoid(),
      name: "linkedin",
      url: "https://www.linkedin.com/in/gustaf-elbander-58590634",
    },
    {
      id: nanoid(),
      name: "github",
      url: "https://github.com/gelbander",
    },
    // {
    //   id: nanoid(),
    //   name: 'facebook',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
